@import "scss/login";
@import "scss/sidebar";
@import "scss/main";
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.mask1 {
  //-webkit-mask-image: url('./userIcon.png');
  // mask-image: url('./images/userIcon.png');
  mask-image: radial-gradient(circle, black 50%, rgba(0, 0, 0, 0.5) 50%);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  //width: 60px;
}

// @font-face {
//     font-family: 'caliber';
//     src: url('./fonts/caliber/Calibri\ Regular.woff');
//     font-weight: 400;
//     font-style: normal;
// }
@font-face {
  font-family: 'caliber';
  src: url('../fonts/CalibriRegular.woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'caliber';
  src: url('../fonts/CalibriBold.woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'caliber';
  src: url('../fonts/CalibriLight.woff');
  font-weight: 300;
  font-style: normal;
}

.fontCaliber{
  font-family: 'caliber' !important;
}

* {
  font-family: 'caliber' !important ; 
}

.fontSize12{
  font-size: 12px;
}

.fontSize13{
  font-size: 13px;
}

