$theme-color: #00c2ad;
$active-link-color: #00aa99;
$white-color: #FFF;
$app-primary-color: red;

$lightGray: #FBFBFC;
$dividerColor: rgba(0, 0, 0, 0.12) !default;
$text: #696969;
$title: #464646;
$subtitle: #545454;
$categoryTitle: #161616;
$tint: #4D226D;
$listColor: rgba(255, 107, 114, 0.53);
$description: #bbbbbb;
$white: #FFFFFF;
$primaryPlaceholder: #874cb073;
$background: "#f2f2f2";
$primaryColor: #4d286e;
$lightPrimaryColor: #996cc3;
$primaryTextColor: #274b74;
$primaryActiveTextColor: #064154;
$borderColor: #C2C1C0;
$gray: #8C8781;
$error: #f44336;
$errorBorder: #f44336;
$primaryBlack: #0D0D39;
$primaryRed: #FF0476;
$primaryBlue: rgb(2, 126, 251);
$lightBlue: #DEE7F1;
$primaryDarkBlue: #227dc7;
$primaryGreen: #f58220;
$primaryGreenLight: #6aecb1;
//$primaryGreenLight: rgba(106, 236, 177, 0.43);
$greenBg: #dafeee;
$cardTextColor: #4B686E;
$capitalize: capitalize;
$uppercase: uppercase;

$full-width: 100% !default;
$standard-width: 75% !default;
$small-width: 25% !default;

$pt: '../../assets/images/Path8573.svg';

$v5: 5px;
$v10: 10px;
$v12: 12px;
$v15: 15px;
$v20: 20px;
$v25: 25px;
$v30: 30px;
$v35: 35px;
$v40: 40px;
$v50: 50px;
$v55: 55px;
$v60: 60px;
$v65: 65px;
$v70: 70px;
$v80: 80px;
$v90: 90px;
$v100: 100px;
$v110: 110px;


